import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'
import DynamicZone from '../components/DynamicZone/DynamicZone'

const articleTemplate = (  { data: { strapi: { article } }, location } ) => {

	return (
		<>
			<Seo title={ article.title } description={ article.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<h1 className="article__header">{ article.title }</h1>
					{ article.subTitle &&
						<h2 className="article__sub-header">{ article.subTitle }</h2>
					}

					<div className="article__body-content">
						{ article && article.content &&
							<DynamicZone components={ article.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default articleTemplate

export const query = graphql`
	query articleQuery($id: ID!) {
		strapi {
			article(id: $id) {
				id
				title
				subTitle
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
				}
			}
		}
	}
`